import React from 'react';

import { container, imgBox, ratio, signature } from './offer-client-opinion-card.module.scss';
import { IClientOpinion } from '../../models/client-opinion.model';

import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface IOfferClientOpinionCardProps {
    className?: string;
    client: IClientOpinion;
}

const OfferClientOpinionCard: React.FC<IOfferClientOpinionCardProps> = ({
    className = '',
    client,
}) => {
    const { media, content, name } = client;

    return (
        <div className={`${container} ${className}`}>
            <ImageWithErrorPlaceholder
                className={imgBox}
                media={media}
                ratioClass={ratio}
                objectFit="contain"
            />
            <p>{content}</p>
            <p className={signature}>{name}</p>
        </div>
    );
};

export default OfferClientOpinionCard;
